import React from "react"
import Header from "../components/Header"
const about = ()=> {
  return(
    <div>
      <Header locale={"en-US"}/>
      About the company!
    </div>
  )
}
export default about
